import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieListeImages from "../../../composants/layout/en/LayoutGalerieListeImages"

import { obtenirImages, obtenirPage } from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageGalerie: allPagesCompilationYaml(
      filter: { code: { eq: "galerieTopImagesEN" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "ic5146Cocon"
            "ngc3372Carene"
            "ngc6188MainsDeDieu"
            "ngc7000"
            "m94"
            "voieLacteeIntegralite"
            "bulbeZetaOphiuchi"
            "telescopeHESSII"
            "auroreTaiga"
            "auroreLappishFireworks"
            "piliersLumiere"
            "ceintureVenus"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["orion", "smc", "megalaxyZoomZoom"] } }
    ) {
      ...FragmentImagePortrait
    }
  }
`

export default function IndexGalerieTop() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageGalerie")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortrait"
  )

  return <LayoutGalerieListeImages page={page} images={images} />
}
